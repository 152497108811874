<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div>
          <flex-petz></flex-petz>
        </div>
        <div class="mt-8">
          <flexPetzSalesbyProduct></flexPetzSalesbyProduct>
        </div>
        <div class="mt-8">
          <flexPetzGeographic></flexPetzGeographic>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import flexPetzGeographic from './flexPetzGeographic.vue'
import flexPetzSalesbyProduct from './flexPetzSalesbyProduct.vue'
import FlexPetz from './flexPetzSales.vue'
export default {
  components: {
    flexPetzGeographic,
    flexPetzSalesbyProduct,
    FlexPetz
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Consumer Sales ◾ FlexPetz'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
